@tailwind base;
@tailwind components;
@tailwind utilities;

@import "fonts.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~slick-carousel/slick/slick.css";

@layer base {
  body {
    @apply font-sans text-tertiary-dark;
  }
}

@layer components {
  .prose {
    @apply text-primary-dark
      max-w-none
      font-normal
      text-lg
      prose-h2:text-5xl
      prose-h2:text-primary-dark
      prose-h2:font-sans
      prose-h2:font-bold
      prose-h3:text-4xl
      prose-h3:font-sans
      prose-h3:font-bold
      prose-h3:text-primary-dark
      prose-h2:mb-8
      prose-h3:mb-8
      prose-h4:text-secondary
      prose-blockquote:font-medium
      prose-blockquote:p-7
      prose-blockquote:not-italic
      prose-blockquote:bg-secondary/25
      prose-blockquote:text-tertiary-dark
      prose-blockquote:border-white
      prose-a:text-primary
      prose-a:decoration-primary
      prose-a:underline-offset-4
      prose-p:mb-8;
  }
}

.slick-track {
  display: flex;
}

.slick-slider {
  line-height: 0;
}

.slick-track .slick-slide {
  display: flex;
  height: auto;
}

.slick-slide > div {
  width: 100%;
}

.slick-prev::before,
.slick-next::before {
  @apply text-secondary content-[''];
}

.slick-prev {
  @apply -left-3 z-20 w-10 h-10 md:w-8 md:h-8;
}

.slider-partner {
  @apply px-10 md:px-8;
}

.slick-next {
  @apply right-0 z-20 w-10 h-10 md:w-8 md:h-8;
}

.slick-center .picture-slider {
  @apply scale-150;
}

.circle-wrapper {
  @apply bg-[length:85%] transition-all duration-500;
}

.slick-center .circle-wrapper {
  @apply bg-[length:100%];
}

.picture-slider {
  @apply transition-transform duration-500 ease-in-out;
}

.prose ul li {
  @apply mb-1 mt-0 list-none before:content-[''] before:inline-block before:h-4 before:w-4 before:mr-1 before:bg-list;
}

.prose ul {
  @apply p-0;
}

.footer-text a {
  @apply text-2xl decoration-black visited:text-primary visited:decoration-primary visited:hover:text-primary transition;
}
.prose a {
  @apply hover:text-primary-light hover:decoration-primary-light visited:decoration-primary visited:hover:text-primary-light transition;
}

.prose a.button {
  @apply inline-block py-1.5 px-3 border-2 text-xl uppercase no-underline font-medium tracking-wider transition;
}

.prose a.button.button--white:not(.inverse) {
  @apply border-black text-black hover:text-white hover:bg-black;
}

.prose a.button.button--white.inverse {
  @apply border-black text-white bg-black hover:text-black hover:bg-white;
}

.prose a.button.button--primary:not(.inverse) {
  @apply border-primary bg-primary text-white hover:bg-transparent hover:text-primary;
}

.prose a.button.button--primary.inverse {
  @apply border-primary bg-transparent text-primary hover:bg-primary hover:text-white;
}

.prose a.button.button--tertiary:not(.inverse) {
  @apply border-secondary bg-secondary text-white hover:bg-transparent hover:text-secondary;
}

.prose a.button.button--tertiary.inverse {
  @apply border-secondary bg-transparent text-secondary hover:bg-secondary hover:text-white;
}

.prose p:first-child {
  margin-top: 0;
}

.prose p:last-child {
  margin-bottom: 0;
}

#mainMenuWrapper {
  @apply fixed xl:relative inset-0 h-screen xl:h-auto overflow-auto xl:overflow-visible bg-white xl:bg-transparent z-50;
}

.menu-ul-0 {
  @apply grid grid-cols-1 gap-8 pt-28 xl:pt-0 xl:flex xl:flex-row xl:items-center xl:space-x-8 text-left xl:text-center xl:ml-32;
}

.menu-li-0 {
  @apply relative;
}

.menu-a-0,
.menu-button-0 {
  @apply xl:block
  xl:w-full
  text-left
  xl:text-center
  border-white
  text-primary-dark
  font-sans
  text-3xl
  xl:text-2xl
  xl:font-sans
  font-medium
  hover:text-secondary
  xl:hover:text-primary-dark
  xl:after:content-['']
  xl:after:absolute
  xl:after:w-full
  xl:after:scale-x-0
  xl:after:h-0.5
  xl:after:bottom-0
  xl:after:left-0
  xl:after:bg-primary-dark
  xl:after:origin-bottom-right
  xl:after:transition-transform
  xl:after:duration-200
  xl:hover:after:scale-x-100
  xl:hover:after:origin-bottom-left;
}

.menu-fixed-a-0 {
  @apply xl:block
  xl:w-full
  text-left
  xl:text-center
  border-white
  text-white
  xl:text-primary-dark
  font-sans
  text-xl
  xl:font-sans
  font-medium
  xl:hover:text-primary-dark
  xl:after:content-['']
  xl:after:absolute
  xl:after:w-full
  xl:after:scale-x-0
  xl:after:h-0.5
  xl:after:bottom-0
  xl:after:left-0
  xl:after:bg-primary-dark
  xl:after:origin-bottom-right
  xl:after:transition-transform
  xl:after:duration-200
  xl:hover:after:scale-x-100
  xl:hover:after:origin-bottom-left;
}

.menu-ul-1 {
  @apply xl:absolute top-full left-1/2 py-8 xl:mt-6 bg-tertiary-dark text-white min-w-max z-20 text-left xl:transform xl:-translate-x-1/2;
}

.affix-up .menu-ul-1 {
  @apply xl:mt-4;
}

.affix-down .menu-ul-1 {
  @apply xl:hidden;
}

.search-bar {
  @apply mt-6;
}

.affix-up .search-bar {
  @apply mt-4;
}

.affix-down .search-bar {
  @apply hidden;
}

.menu-ul-1:after {
  @apply hidden xl:inline-block absolute top-0 left-1/2 h-4 w-4 bg-tertiary-dark transform rotate-45 -translate-x-1/2 -translate-y-1/2;
  content: "";
}

.menu-li-1 {
  @apply relative;
}

.menu-a-1,
.menu-button-1 {
  @apply block py-2 xl:px-14 bg-transparent hover:text-white xl:hover:text-white xl:hover:bg-tertiary transition;
}

.menu-a-1[aria-current="page"] {
  @apply text-white;
}

.menu-a-1:before,
.menu-button-1:before {
  @apply hidden xl:inline-block text-white mr-4;
  content: "\2022";
}
