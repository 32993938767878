@font-face {
  font-family: "Belleza";
  src: url("../fonts/Belleza/Belleza-Regular.woff2") format("woff2"),
    url("../fonts/Belleza/Belleza-Regular.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow Condensed";
  src: url("../fonts/BarlowCondensed/BarlowCondensed-ExtraLight.woff2")
      format("woff2"),
    url("../fonts/BarlowCondensed/BarlowCondensed-ExtraLight.woff")
      format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow Condensed";
  src: url("../fonts/BarlowCondensed/BarlowCondensed-Regular.woff2")
      format("woff2"),
    url("../fonts/BarlowCondensed/BarlowCondensed-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow Condensed";
  src: url("../fonts/BarlowCondensed/BarlowCondensed-SemiBold.woff2")
      format("woff2"),
    url("../fonts/BarlowCondensed/BarlowCondensed-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow Condensed";
  src: url("../fonts/BarlowCondensed/BarlowCondensed-Bold.woff2")
      format("woff2"),
    url("../fonts/BarlowCondensed/BarlowCondensed-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
